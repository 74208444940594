import {ADCountries} from "../utilities/CommonConst";
const authenticateAdUser = {
    mounted() {
        this.$store
            .dispatch("client/GetADEmpDetails", "")
            .then((res) => {
                if (res.data.Country != null && ADCountries.some(country => country.toLowerCase() == res.data.Country.toLowerCase())) {
                    return true;
                } else {
                    this.$router.push({
                        name: "error",
                        params: {
                            message: "UnAuthorized"
                        },
                    });
                }
            })
    },
};
export default authenticateAdUser;